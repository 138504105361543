/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

//@import "./assets/scss/variables";
//

@import './assets/scss/modal';
//@import './assets/scss/bootstrap/scss/modal';
//@import "./assets/scss/classes";
//@import "./assets/scss/important_classes";
//@import "./assets/scss/animations";
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animation-slide-up {
  animation: slideUp 0.5s ease-out forwards;
}

.swal2-popup{
  min-width: 300px !important;
  background-color: black !important;
  color: white;
}

.swal2-confirm{
  color: white;
  background-color: rgb(179, 0, 47);
  border-color: rgb(179, 0, 47);
}
.swal2-container {
  z-index: 9999999 !important;
}
::ng-deep.swal2-container {
  z-index: 1000000;
}

.my-swal {
  z-index: 90000 !important;
}

/* For WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  border-radius: 20px; /* Rounded scrollbar itself */
}

/* Scrollbar Track (Background) */
::-webkit-scrollbar-track {
  background: #000000; /* Black background for the track */
  border-radius: 20px; /* Rounded corners for the track */
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.1); /* Optional: slight inner shadow */
}

/* Scrollbar Handle (Thumb) */
::-webkit-scrollbar-thumb {
  background-color: #efecec; /* Light color for the scrollbar handle */
  border-radius: 20px !important;       /* Round the scrollbar handle */
  border: 2px solid #000000; /* Black border to blend with the background */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
}

/* Horizontal Scrollbar */
::-webkit-scrollbar-horizontal {
  height: 8px; /* Thin scrollbar height for horizontal scrollbars */
  border-radius: 20px; /* Rounded scrollbar itself */
}

/* Horizontal Track */
::-webkit-scrollbar-track-horizontal {
  border-radius: 20px; /* Rounded corners for the horizontal track */
}

/* Horizontal Thumb */
::-webkit-scrollbar-thumb-horizontal {
  border-radius: 20px; /* Rounded corners for the horizontal scrollbar thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #423338 #000000; /* Light thumb and black background */
}



